import * as Sentry from '@sentry/node';

export * from './helmet';

export function isNodeVisible({ top, bottom }) {
  return window.innerHeight > top && bottom > 0;
}

// 实验性检测MAC M1芯片的方法，不一定准确
// https://stackoverflow.com/questions/65146751/detecting-apple-silicon-mac-in-javascript
export function hackDetectM1Chip() {
  const w = document.createElement('canvas').getContext('webgl');
  const d = w.getExtension('WEBGL_debug_renderer_info');
  const g = (d && w.getParameter(d.UNMASKED_RENDERER_WEBGL)) || '';
  if (g.match(/Apple/) && !g.match(/Apple GPU/)) {
    //  ...definitely arm...
    return true;
  }
  if (
    w.getSupportedExtensions().indexOf('WEBGL_compressed_texture_s3tc_srgb') ===
    -1
  ) {
    // ...probably arm...
    return true;
  }
  return false;
}

export const checkDownloadUrl = async (url) => {
  // 兼容处理
  let controller = null;
  if (AbortController) {
    controller = new AbortController();
  }
  const TIMEOUT = 20000; // url请求超时时间
  const startTime = Date.now();
  const id = setTimeout(() => {
    controller?.abort();
    Sentry.captureException(new Error(`Download URL TIMEOUT: ${url}`));
  }, TIMEOUT);
  try {
    await fetch(url, {
      mode: 'no-cors',
      signal: controller?.signal || null,
    });
    // 检测到可访问后 取消请求 (e.g:下载的请求)
    controller?.abort();
    return true;
  } catch (error) {
    if (Date.now() >= startTime + TIMEOUT) {
      Sentry.captureException(new Error(`Download URL TIMEOUT: ${url}`));
      return false;
    }
    Sentry.captureException(new Error(`Invalid Download URL: ${url}`));
    return false;
  } finally {
    clearTimeout(id);
  }
};

/**
 * 检测是否在safari浏览器运行
 *
 * https://stackoverflow.com/questions/7944460/detect-safari-browser
 * 由于Chrome浏览器的userAgent也有Safari字段, 故需要过滤处理
 */
export const isSafariBrowser = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const openUrl = (url) => {
  if (isSafariBrowser()) {
    // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
    // https://stackoverflow.com/questions/11523379/jquery-window-openurl-on-ipad
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.target = '_blank';
    a.style = 'display: none';
    a.href = url;
    a.click();
    document.body.removeChild(a);
    return null;
  }

  return window.open(url, '_blank');
};

export function downloadDesktopApp(isMac, isMacM1, onError = null) {
  const platform = isMac
    ? isMacM1
      ? 'darwin-arm64'
      : 'darwin-x64'
    : 'win32-x64';
  const key = `${platform}-prod`;
  const updateJsonUrl = `https://seatalkweb.com/updates.json?_=${new Date().getTime()}`;
  fetch(updateJsonUrl)
    .then((resp) => resp.json())
    .then((data) => {
      const { [key]: { install: downloadUrl = '' } = {} } = data || {};
      openUrl(downloadUrl);
    })
    .catch((e) => {
      console.error('fetch update json error:', e, onError);
      Sentry.captureException(
        new Error(`Invalid Download URL: ${updateJsonUrl}`)
      );
      onError && onError(e);
    });
}

export const isEmptyString = (string) => {
  return (
    string === undefined ||
    string === null ||
    string === '' ||
    (typeof value === 'string' && !string.replace(/\s/g, '').length) // string with multiple whitespace characters
  );
};

export function openNewTab(href) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href,
  }).click();
}

export function hasUnicode(str) {
  if (!str) {
    return false;
  }

  for (let i = 0; i < str.length; i += 1) {
    // https://en.wikipedia.org/wiki/ASCII
    // ASCII defines characters from 0 to 127,
    // so if a character in the string has a code greater than that, then it is a Unicode character
    if (str.charCodeAt(i) > 127) {
      return true;
    }
  }

  return false;
}

export const deleteUndefined = (obj) => {
  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        deleteUndefined(obj[key]);
      } else if (typeof obj[key] === 'undefined') {
        delete obj[key]; // eslint-disable-line no-param-reassign
      }
    });
  }
};

export const getParameterFromURL = (name, url = '') => {
  const regex = new RegExp(
    `[?&]${name.replace(/[\\[\]]/g, '\\$&')}(=([^&#]*)|&|#|$)`
  );
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const serializeObjectToQueryString = (obj) => {
  const str = `?${Object.keys(obj)
    .reduce((a, k) => {
      a.push(`${k}=${encodeURIComponent(obj[k])}`);
      return a;
    }, [])
    .join('&')}`;

  return str;
};

export const isBrowserSupportWebp = () => {
  const canvas = document.createElement('canvas');
  const isSupportWebp =
    canvas.toDataURL('image/webp')?.indexOf('data:image/webp') === 0;

  canvas.remove();

  return isSupportWebp;
};
