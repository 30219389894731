import { getMMSLink } from 'configs/env';

export const ApplicationSources = {
  DropOff: 1,
  ContactSales: 2,
};

export const ErrorCodes = {
  Success: 0,
  UnknownError: 1,
  Unauthorized: 2,
  PermissionDenied: 3,
  BadRequest: 4,
  InvalidOTP: 5,
  ExpiredOTP: 6,
  TooManyRequest: 7,
  InvalidOTPEmail: 8,
  BadFieldRequest: 9,
  MultipleBadFieldRequest: 10,
};

export const PortalResponseCodes = {
  Success: 0,
  ServerError: 3,
  ParamMissing: 4,
  ParamInvalid: 5,
  Unauthorized: 6,
  TokenInvalid: 7,
  ResourceQuotaExceeded: 8,
  AccountNotExists: 9,
  AccountDeleted: 10,
  OTPInvalid: 11,
  OTPExpired: 12,
  ThirdPartyTokenInvalid: 13,
  OTPRestricted: 14,
  AccountDeletionUnavailable: 15,
  EmailIsGoogle: 16,
  AccountDeactivated: 17,
  NeedCaptcha: 51,
  InvalidCaptchaSingature: 52,
};

export const UtmCodes = {
  Source: 'utm_source',
  Medium: 'utm_medium',
  Campaign: 'utm_campaign',
};

export const HuaWeiLegalDomain = {
  live: 'https://official.haiserve.com',
  staging: 'https://official.staging.haiserve.com',
  test: 'https://official.test.haiserve.com',
};

export const INPUT_LENGTH = 128;
export const PHONE_NO_LENGTH = 16;

export const LOGO_SIZE = {
  MOBILE: '0.48rem',
  DESKTOP: '24',
};

export const ENDPOINTS = {
  requestOTP: '/account/login/otp_request',
  verifyOTP: '/account/login/otp_verify',
  verifyOAuth: '/account/login/oauth_verify',
  prepareDelete: '/account/delete/prepare',
  deleteAccount: '/account/delete/confirm',
};

export const E_LOGIN_METHOD = {
  PHONE: 1,
  EMAIL: 2,
};

export const E_OAUTH_PROVIDER = {
  GOOGLE: 1,
  FACEBOOK: 3,
  APPLE: 4,
};

export const E_OAUTH_SCRIPT_STATE = {
  NOT_LOADED: 'not_loaded',
  LOADED: 'loaded',
  ERROR: 'error',
};

const mmsUrl = getMMSLink();

export const ImageToMmsMap = {
  'unsub_error.png': 'id-11134005-7r98u-lprnz1adrk2882',
  'st-home-img-org-attendance-v2.png': 'id-11134005-7r98o-lprnz17vv75y2d',
  'st-feature-commu-img-translation.png': 'id-11134005-7r98q-lprnz10o5ri7d6',
  'st-home-ic-collaboration.png': 'id-11134005-7r98q-lprnz1546a60bf',
  'st-home-ic-communication-mobile.png': 'id-11134005-7r98t-lprnz15nygcl74',
  'loginImgLogoSeatalk.png': 'id-11134005-7r98u-lprnz0r8jkg51d',
  'st-home-ic-allin-date.svg': 'id-11134005-7r991-lprnz1400w0ud7',
  'st-feature-management-img-claim-adminportal.png':
    'id-11134005-7r992-lprnz136248ed9',
  'download-button-macm1-white@2x.png': 'id-11134005-7r98s-lprnz0o6o18ldd',
  'st-home-img-org-claim-v2.png': 'id-11134005-7r98t-lprnz17vv7m86f',
  'st-feature-colla-img-department-group.png':
    'id-11134005-7r98u-lprnz0wsbfugf3',
  'st-feature-sop-img-bot.png': 'id-11134005-7r98o-lprnz0msq2db30',
  'seatalk-web-logo.png': 'id-11134005-7r98r-lprnz0v4dw0gf3',
  'mobile-nav-arrow-up.png': 'id-11134005-7r98t-lprnz0rsirji29',
  'st-home-ic-allin-pic.svg': 'id-11134005-7r991-lprnz14tznyebd',
  'st-feature-sop-ic-top-login.png': 'id-11134005-7r98p-lprnz0msq2ni21',
  'download-button-playstore-white@2x.png': 'id-11134005-7r98y-lprnz0oqn7w24c',
  'st-home-ic-security-server.png': 'id-11134005-7r98v-lprnz16rwtmg6f',
  'st-feature-commu-img-call.png': 'id-11134005-7r98o-lprnz0za7sr41b',
  'nav_ic_appstore_blue.png': 'id-11134005-7r98p-lprnz0schy0v0b',
  'st-home-ic-security-lock.png': 'id-11134005-7r98x-lprnz167xn5r97',
  'st-feature-management-img-attendance-mobile.png':
    'id-11134005-7r98t-lprnz12m2x51a1',
  'st-home-nav-ic-translate.png': 'id-11134005-7r98p-lprnz19tsdf287',
  'nav_ic_playstore_blue.png': 'id-11134005-7r992-lprnz0smhj3s00',
  'seatalk-story.jpg': 'id-11134005-7r992-lprnz0v4dvun7a',
  'st-home-img-video-play.png': 'id-11134005-7r98o-lprnz18ptzvy36',
  'soundwave.gif': 'id-11134005-7r98v-lprnz0vega2gf4',
  'download-button-huaweistore-white@2x.png':
    'id-11134005-7r98u-lprnz0nmou86a8',
  'st-feature-commu-img-combineforward.png': 'id-11134005-7r990-lprnz0zk7do81e',
  'back-icon.png': 'id-11134005-7r98x-lprnz0n2pnww71',
  'download-button-macintel-gray@2x.png': 'id-11134005-7r991-lprnz0nmouind6',
  'collapse-icon.png': 'id-11134005-7r98p-lprnz0msrgxrfc',
  'st-feature-colla-ic-team-leave.png': 'id-11134005-7r991-lprnz0vycnuace',
  'st-feature-management-img-leave-mobile.png':
    'id-11134005-7r98z-lprnz13q2pg056',
  'st-feature-commu-bot-ic-bublicity.png': 'id-11134005-7r98o-lprnz0xw9tof65',
  'unsub_confirm.png': 'id-11134005-7r98w-lprnz1adrjwff3',
  'st-feature-commu-ic-top-pic.png': 'id-11134005-7r98w-lprnz0za7slb94',
  'oa-logo.png': 'id-11134005-7r992-lprnz0swh4qtec',
  'icon-leave-up.png': 'id-11134005-7r990-lprnz0qyjz9s1b',
  'st-feature-management-img-top.png': 'id-11134005-7r98u-lprnz0msrh7yb9',
  'one-stop-for-work.png': 'id-11134005-7r98z-lprnz0t6gqgea7',
  'st-home-nav-ic-arrow-left-white.png': 'id-11134005-7r98w-lprnz19jsrww2b',
  'st-feature-sop-ic-top-apps.png': 'id-11134005-7r990-lprnz0n2png833',
  'nav_ic_playstore_black.png': 'id-11134005-7r98o-lprnz0schy6o65',
  'st-home-nav-ic-translate-black.png': 'id-11134005-7r98x-lprnz19tscz6a6',
  'st-home-ic-security-safe-2.png': 'id-11134005-7r992-lprnz16rwu34c8',
  'st-home-ic-allin-safe.png': 'id-11134005-7r98u-lprnz14tzo8v3b',
  'seatalk-help-business.jpg': 'id-11134005-7r98t-lprnz0ukg3xq3b',
  'st-home-img-video-borderbottomleft.png': 'id-11134005-7r98q-lprnz185ussl2f',
  'seatalk-improve-employee-productivity.jpg':
    'id-11134005-7r98u-lprnz0uuea6e22',
  'st-home-nav-ic-logo-white.png': 'id-11134005-7r98p-lprnz19jsrphd4',
  'st-feature-colla-ic-top-date.png': 'id-11134005-7r98r-lprnz0wibuxc94',
  'download-button-playstore-gray@2x.png': 'id-11134005-7r98r-lprnz0oqn7z2cb',
  'st-home-img-video-bordertopright.png': 'id-11134005-7r98o-lprnz18fue5a10',
  'download-button-appstore-gray@2x.png': 'id-11134005-7r991-lprnz0n2sgtaa8',
  'mobile-nav-arrow-down.png': 'id-11134005-7r98q-lprnz0rij5succ',
  'st-home-ic-tools.png': 'id-11134005-7r991-lprnz171wf5e82',
  'open-icon.png': 'id-11134005-7r98x-lprnz0tggb9i9e',
  'st-feature-commu-bot-ic-report.png': 'id-11134005-7r98t-lprnz0y69ercb3',
  'download-button-windows-gray@2x.png': 'id-11134005-7r98t-lprnz0oqn8by12',
  'contact-sales-thank-you.png': 'id-11134005-7r98u-lprnz0mssvcia9',
  'st-feature-management-img-approval-mobile.png':
    'id-11134005-7r98z-lprnz12c3byo71',
  'st-feature-management-img-checkin-mobile.png':
    'id-11134005-7r98z-lprnz12w2ieq21',
  'st-feature-colla-ic-top-chart.png': 'id-11134005-7r98o-lprnz0wiburjd5',
  'st-home-ic-allin-chat.png': 'id-11134005-7r98p-lprnz1400w1x9e',
  'home-page-s-illustrator.png': 'id-11134005-7r98y-lprnz0pullj997',
  'download-button-androidbundle-white@2x.png':
    'id-11134005-7r98s-lprnz0n2r29xaa',
  'seatalk-download-test-logo.png': 'id-11134005-7r98x-lprnz0ukeoxe11',
  'st-feature-commu-img-noticebot-warehouse.png':
    'id-11134005-7r98v-lprnz1046kky53',
  'download_close_icon.png': 'id-11134005-7r98v-lprnz0msq22u3c',
  'external-app-arrow.png': 'id-11134005-7r98v-lprnz0pametbb1',
  'process-to-auth-qr.png': 'id-11134005-7r98v-lprnz0tqfwxrc4',
  '600_timer.gif': 'id-11134005-7r98v-lprnz0ncp8u04e',
  'st-feature-management-img-claim-mobile.png':
    'id-11134005-7r98q-lprnz13623nq7f',
  'st-feature-management-img-leave-adminportal.png':
    'id-11134005-7r98s-lprnz13g1pbzf4',
  'st-home-nav-ic-arrow-black.png': 'id-11134005-7r990-lprnz19jsrgm31',
  'st-home-ic-allin-phone.png': 'id-11134005-7r98v-lprnz14tzoj258',
  'download-button-appstore-white@2x.png': 'id-11134005-7r98z-lprnz0n2pnle5e',
  'st-feature-colla-img-team-info.png': 'id-11134005-7r98v-lprnz0xcan72d5',
  'st-feature-commu-img-vioce-map.svg': 'id-11134005-7r98r-lprnz10o5ro0c2',
  'login_img_illustration.png': 'id-11134005-7r991-lprnz0r8jk6w87',
  'download-button-huaweistore-gray@2x.png': 'id-11134005-7r98u-lprnz0ncp9f26c',
  'st-feature-management-img-attendance-adminportal.png':
    'id-11134005-7r98s-lprnz12c3bi032',
  'st-feature-collaboration-img-top.png': 'id-11134005-7r98y-lprnz0xma8064a',
  'st-home-ic-communication.png': 'id-11134005-7r992-lprnz15nygbie3',
  'st-home-bg-wave-dark.png': 'id-11134005-7r98v-lprnz13q1aew4b',
  'st-home-ic-allin-message.svg': 'id-11134005-7r98y-lprnz14a0hbm9c',
  'st-feature-commu-img-noticebot-guest.png':
    'id-11134005-7r98r-lprnz1046knydf',
  'st-feature-sop-img-top.png': 'id-11134005-7r992-lprnz0n2pnph3b',
  'icon-approval-up.png': 'id-11134005-7r991-lprnz0qeks6q40',
  'st-feature-commu-whisper-ic-message.png': 'id-11134005-7r98y-lprnz11i4jku5a',
  'download_icon_clear_type.png': 'id-11134005-7r98z-lprnz0n2pnoe06',
  'st-feature-commu-img-recall.png': 'id-11134005-7r98q-lprnz10e66emff',
  'st-home-img-org-approval-v2.png': 'id-11134005-7r98u-lprnz17vv7qm41',
  'st-home-nav-ic-arrow-blue.png': 'id-11134005-7r98v-lprnz19jsrr310',
  'st-feature-commu-convenient-pattern-status.png':
    'id-11134005-7r98v-lprnz0za7svi3a',
  'download_section1_main_mockup.png': 'id-11134005-7r98q-lprnz0msrgs20a',
  'contact-sales-ty-2.png': 'id-11134005-7r98u-lprnz0mssvi723',
  'st-home-img-org-checkin-v2.png': 'id-11134005-7r98o-lprnz17vv7gfdd',
  'st-feature-commu-img-noticebot-calendar.png':
    'id-11134005-7r98o-lprnz1046kp122',
  'st-feature-commu-img-reply.png': 'id-11134005-7r98v-lprnz10e65ty8d',
  'nav_ic_appstore_black.png': 'id-11134005-7r98q-lprnz0s2iccm95',
  'st-home-ic-collaboration-mobile.png': 'id-11134005-7r98o-lprnz153z9sgab',
  'st-feature-management-ic-top-collab.png': 'id-11134005-7r98u-lprnz11s45bif5',
  'st-feature-commu-ic-top-chat.png': 'id-11134005-7r98y-lprnz0za7saud6',
  'st-feature-management-img-checkin-adminportal.png':
    'id-11134005-7r98w-lprnz12w2ihq3b',
  'soundwave2.gif': 'id-11134005-7r991-lprnz0vedh6t22',
  'st-feature-communiation-img-top.png': 'id-11134005-7r992-lprnz11i4jhu15',
  'icon-claim-management.png': 'id-11134005-7r98s-lprnz0qyjz3z5f',
  'st-feature-commu-bot-ic-staff.png': 'id-11134005-7r98o-lprnz0y69f0ldc',
  'api-issue.png': 'id-11134005-7r990-lprnz0n2r28ue3',
  'st_home_video_preview.jpg': 'id-11134005-7r98t-lprnz0vod2ji4b',
  'st-feature-colla-img-org.png': 'id-11134005-7r98t-lprnz0x2b1deac',
  'contact-sales.png': 'id-11134005-7r98t-lprnz0msrh3ke8',
  'st-feature-sop-img-dev.png': 'id-11134005-7r98w-lprnz0msq27m0c',
  'download-button-androidbundle-gray@2x.png':
    'id-11134005-7r991-lprnz0n2sgud76',
  'unsub_success.png': 'id-11134005-7r98y-lprnz1axqqmd5d',
  'icon-attendance.png': 'id-11134005-7r98r-lprnz0qeksmm6e',
  'st-feature-commu-convenient-pattern-history.png':
    'id-11134005-7r991-lprnz0yg90da01',
  'st-home-ic-tools-mobile.png': 'id-11134005-7r990-lprnz171wf8ee6',
  'st-home-nav-ic-logo@2x.png': 'id-11134005-7r992-lprnz19jsroea7',
  'one-stop-for-work.jpg': 'id-11134005-7r98s-lprnz0t6gq0ic8',
  'icon-checkin-up.png': 'id-11134005-7r98t-lprnz0qokdfq15',
  'st-feature-colla-img-employee-manage.png':
    'id-11134005-7r98t-lprnz0wsbg3p3c',
  'st-home-ic-map.png': 'id-11134005-7r98u-lprnz15nyg8i26',
  'download-button-macm1-gray@2x.png': 'id-11134005-7r98r-lprnz0nwoflk88',
  'st-home-img-org-leave-v2.png': 'id-11134005-7r98y-lprnz185usjce5',
  'st-feature-colla-img-onboard.png': 'id-11134005-7r98y-lprnz0x2b1ge31',
  'st-feature-commu-convenient-pattern-status_mobile.png':
    'id-11134005-7r98w-lprnz0yq8lo2bf',
  'sop-logo.png': 'id-11134005-7r98x-lprnz0msq2j4f8',
  'question-mark.svg': 'id-11134005-7r98o-lprnz0tqfx3k4b',
  'account-not-supported.png': 'id-11134005-7r98v-lprnz0mssvsecc',
  'st-feature-colla-ic-team-tree.png': 'id-11134005-7r991-lprnz0w8c93a9e',
  'mobile-nav-arrow-down.png.png': 'id-11134005-7r991-lprnz0rsir3m1e',
  'st-home-ic-platform.png': 'id-11134005-7r990-lprnz167xmvad3',
  'st-home-ic-security-whisper.png': 'id-11134005-7r98r-lprnz16rwtvp69',
  'seatalk-download-logo.svg': 'id-11134005-7r98u-lprnz0uaf3mm19',
  'download-button-windows-white@2x.png': 'id-11134005-7r98q-lprnz0p0mt52de',
  'st-feature-sop-img-login.png': 'id-11134005-7r98p-lprnz0msrgnadc',
  'st-feature-commu-whisper-ic-lock.png': 'id-11134005-7r98y-lprnz1184y8592',
  'regis-welcome.png': 'id-11134005-7r98x-lprnz0uaf3np56',
  'st-feature-colla-ic-team-list.png': 'id-11134005-7r98y-lprnz0vycoa64f',
  'st-feature-commu-bot-ic-policy.png': 'id-11134005-7r992-lprnz0xwb8eo57',
  'st-feature-management-ic-top-date.png': 'id-11134005-7r98v-lprnz11s44qu85',
  'timer.gif': 'id-11134005-7r991-lprnz1adrjlyed',
  'download-button-macintel-white@2x.png': 'id-11134005-7r98w-lprnz0nwog28ca',
  'st-feature-management-img-approval-adminportal.png':
    'id-11134005-7r991-lprnz1223qf3ae',
  'st-home-ic-platform-mobile.png': 'id-11134005-7r98y-lprnz167xnfy15',
  'one-stop-for-work-inner.png': 'id-11134005-7r98o-lprnz0swh4pq66',
  'download_bg_texture_material.png': 'id-11134005-7r98p-lprnz0n2r20odb',
  'st-home-nav-ic-arrow@2x.png': 'id-11134005-7r98r-lprnz19jsrg80c',
  'home-page-banner-mobile.png': 'id-11134005-7r98z-lprnz0pullqo26',
  'st-feature-commu-img-whisperbubble.png': 'id-11134005-7r992-lprnz1184xyw1f',
  'home-page-banner.png': 'id-11134005-7r992-lprnz0pulla0d8',
  'regis-received.jpg': 'id-11134005-7r98y-lprnz0u0fi0od6',
  'contact-sales-ty-1.png': 'id-11134005-7r98q-lprnz0mssv7q16',
  'how-to-scan.png': 'id-11134005-7r991-lprnz0qf1n32fb',
  'unsub_preunsub.png': 'id-11134005-7r98p-lprnz1ansjjsd9',
  'st-home-img-video-dots.jpg': 'id-11134005-7r98z-lprnz18fue2a25',
  'voice-call.png': 'id-11134005-7r991-lprnz1axqqla12',
  'vi/st-feature-management-img-checkin-mobile.png': `${mmsUrl}/id-11134005-7r98w-lprsj3ekvmrc9f`,
  'vi/st-feature-communiation-img-top.png': `${mmsUrl}/id-11134005-7r98w-lprsj3ekx1wu82`,
  'vi/st-feature-management-img-leave-mobile.png': `${mmsUrl}/id-11134005-7r98u-lprsj3euv8aa90`,
  'vi/st-feature-collaboration-img-top.png': `${mmsUrl}/id-11134005-7r98w-lprsj3ekvnce2a`,
  'vi/st-home-img-org-leave-v2.png': `${mmsUrl}/id-11134005-7r98p-lprsj3fou0ag2d`,
  'vi/st-feature-colla-img-team-info.png': `${mmsUrl}/id-11134005-7r98v-lprsj3e0wgggec`,
  'vi/st-feature-commu-convenient-pattern-status_mobile.png': `${mmsUrl}/id-11134005-7r992-lprsj3ekvn804a`,
  'vi/st-feature-commu-img-noticebot-calendar.png': `${mmsUrl}/id-11134005-7r98p-lprsj3e0wgkue5`,
  'vi/how-to-scan.png': `${mmsUrl}/id-11134005-7r98t-lprsj3ekx1bs08`,
  'vi/st-feature-commu-img-noticebot-warehouse.png': `${mmsUrl}/id-11134005-7r98y-lprsj3e0wfzs1c`,
  'vi/st-feature-commu-img-recall.png': `${mmsUrl}/id-11134005-7r98z-lprsj3eb79xi5d`,
  'vi/st-home-img-org-attendance-v2.png': `${mmsUrl}/id-11134005-7r98t-lprsj3fou0auec`,
  'vi/st-home-img-org-checkin-v2.png': `${mmsUrl}/id-11134005-7r98u-lprsj3fou0lbd5`,
  'vi/st-feature-management-img-checkin-adminportal.png': `${mmsUrl}/id-11134005-7r991-lprsj3e0wgan51`,
  'vi/st-feature-management-img-attendance-adminportal.png': `${mmsUrl}/id-11134005-7r98x-lprsj3euv8da60`,
  'vi/st-feature-management-img-attendance-mobile.png': `${mmsUrl}/id-11134005-7r98w-lprsj3e0wg065c`,
  'vi/st-feature-management-img-leave-adminportal.png': `${mmsUrl}/id-11134005-7r98q-lprsj3euv8ed4f`,
  'vi/st-feature-management-img-claim-mobile.png': `${mmsUrl}/id-11134005-7r98w-lprsj3ekyfw867`,
  'vi/st-feature-commu-convenient-pattern-history.png': `${mmsUrl}/id-11134005-7r98r-lprsj3ekvn0ldc`,
  'vi/st-feature-colla-img-department-group.png': `${mmsUrl}/id-11134005-7r990-lprsj3e0wg7y5b`,
  'vi/st-feature-commu-convenient-pattern-status.png': `${mmsUrl}/id-11134005-7r98t-lprsj3e0wg4y2a`,
  'vi/st-feature-management-img-approval-adminportal.png': `${mmsUrl}/id-11134005-7r98u-lprsj3ekvmrqa7`,
  'vi/st-feature-management-img-approval-mobile.png': `${mmsUrl}/id-11134005-7r98s-lprsj3ekx1mn64`,
  'vi/st-feature-commu-img-reply.png': `${mmsUrl}/id-11134005-7r98q-lprsj3eaw1u86b`,
  'vi/st-feature-management-img-claim-adminportal.png': `${mmsUrl}/id-11134005-7r992-lprsj3ekx1sga0`,
  'vi/st-feature-commu-img-noticebot-guest.png': `${mmsUrl}/id-11134005-7r98s-lprsj3e0wg91f5`,
  'vi/st-feature-commu-img-combineforward.png': `${mmsUrl}/id-11134005-7r98o-lprsj3eaw1mte8`,
  'vi/st-home-img-org-approval-v2.png': `${mmsUrl}/id-11134005-7r990-lprsj3f4uu3y55`,
  'vi/st-feature-colla-img-org.png': `${mmsUrl}/id-11134005-7r98r-lprsj3ekvmzi95`,
  'vi/st-feature-commu-img-translation.png': `${mmsUrl}/id-11134005-7r98t-lprsj3ekvn27df`,
  'vi/st-feature-colla-img-employee-manage.png': `${mmsUrl}/id-11134005-7r98t-lprsj3ekvmwiaf`,
  'vi/st-home-img-org-claim-v2.png': `${mmsUrl}/id-11134005-7r98t-lprsj3fou0r41f`,
  'vi/seatalk-help-business.png': `${mmsUrl}/id-11134005-7r98v-lprsj3ekx1gy50`,
  'vi/st-feature-colla-img-onboard.png': `${mmsUrl}/id-11134005-7r98o-lprsj3ekx1jy2b`,
};

export const SESSION_STORAGE_KEY = {
  IS_GOOGLE_FLOW: 'sow_isg',
  TIMESTAMP_SESSION: 'sow_timestamp_session',
  USERNAME: 'sow_username',
  VERIFICATION_TOKEN: 'sow_verification_token',
};

export const RESPONSE_FLAG = {
  GMAIL: 'gmail',
};
