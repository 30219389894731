import * as Sentry from '@sentry/node';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { node } from 'prop-types';
import { portalAxiosInstance } from 'configs/env';

export const LocationInfoContext = createContext(null);
export const useLocationInfoContext = () => useContext(LocationInfoContext);

const LocationTimeout = 5e3;
function LocationInfoProvider({ children }) {
  const [isLocFetching, setLocFetching] = useState(true);
  const [isLocError, setLocError] = useState(false);

  const [isCN, setCN] = useState();
  const [CountryCode, setCountryCode] = useState('');
  const [VisitorIP, setVisitorIP] = useState('');

  async function getLocationByIp() {
    try {
      const axiosRes = await portalAxiosInstance.get('/package/location');
      if (axiosRes.status === 200) {
        // Get Location SUCCESS
        if (axiosRes?.data?.code === 0) {
          return axiosRes.data;
        }
      }
      return {};
    } catch (e) {
      return {};
    }
  }

  useEffect(() => {
    let timer;
    const t1 = +new Date();
    setLocFetching(true);
    const getLocationTimeout = () => {
      return new Promise((resolve) => {
        timer = setTimeout(() => {
          setTimeout(() => {
            resolve(null);
          }, LocationTimeout);
        });
      });
    };
    Promise.race([getLocationTimeout(), getLocationByIp()])
      .then((data) => {
        const t2 = +new Date();
        clearTimeout(timer);
        if (data && data.location) {
          setLocError(false);
          setCountryCode(data.location || '');
          setVisitorIP(data.client_ip || '');
          setCN(data.location === 'CN');
        } else {
          setLocError(true);
        }

        Sentry.setExtra('second', ((t2 - t1) / 1000).toFixed(3));
        Sentry.setExtra('fallback_displayed', isLocError ? 'No' : 'Yes');
        Sentry.setTag('fallback_displayed', isLocError ? 'No' : 'Yes');

        Sentry.captureEvent({
          message: 'qrcode_redirection_webpage_api_result_time',
          level: Sentry.Severity.Info,
        });
      })
      .finally(() => {
        setLocFetching(false);
      });
  }, []);

  return (
    <LocationInfoContext.Provider
      value={{
        getLocationByIp,
        isLocFetching,
        isLocError,
        isCN,
        CountryCode,
        VisitorIP,
      }}
    >
      {children}
    </LocationInfoContext.Provider>
  );
}

LocationInfoProvider.propTypes = {
  children: node,
};

export default LocationInfoProvider;
