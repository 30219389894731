import * as Sentry from '@sentry/node';

export const initSentry = () => {
  if (
    process.env.BUILD_ENV &&
    process.env.SENTRY_DSN &&
    process.env.BUILD_TIMESTAMP
  ) {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      release: process.env.BUILD_TIMESTAMP,
      environment: process.env.BUILD_ENV,
      // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });
  } else {
    // 本地开发环境走这里，上报到dev环境
    Sentry.init({
      dsn: 'https://b15e5773c497462286ef1df61bb64d6c@o506518.ingest.sentry.io/5596722',
      environment: 'dev',
    });
  }
};
